import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Link } from '@reach/router';
import { ArrowRightIcon } from '@heroicons/react/solid';
import CTA from '../components/CTA';

export const query = graphql`
  query {
    supportPosts: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/support/**" } }
    ) {
      edges {
        node {
          fileAbsolutePath
          id
          frontmatter {
            date
            slerp
            slug
            title
          }
        }
      }
    }
  }
`;

export default function Support({ data }) {
  const posts = data.supportPosts.edges.map((e) => e.node);
  return (
    <Layout>
      <SEO title="Support" />
      <div className="bg-indigo-50 w-full pt-36 pb-28">
        <h1 className="heading-sm text-center">Support</h1>
        <p className="sub-sm mx-auto max-w-4xl text-center mt-8">
          Få tips og tricks til, hvordan Sails gør din hverdag med salg lettere.
          Du er altid velkommen til at kontakte os på{' '}
          <a className="underline" href="mailto:support@get-sails.dk">
            support@get-sails.dk
          </a>{' '}
          hvis du har brug for hjælp.
        </p>
      </div>
      <div className="container py-20">
        <h2 className="heading-xs mb-4">Artikler</h2>
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {posts.map((post) => (
            <Link
              key={post.id}
              to={`/support/${post.frontmatter.slug}`}
              className="p-4 md:p-8 rounded-lg border border-gray-100 transition-shadow hover:border-0 hover:shadow-lg"
            >
              <h3 className="text-lg lg:text-xl font-semibold mb-1">
                {post.frontmatter.title}
              </h3>
              <p>{post.frontmatter.slerp}</p>
              <p className="flex items-center text-base mt-4 text-blue-600">
                <span>Læs mere</span>
                <ArrowRightIcon className="w-5 h-5 ml-2 flex-shrink-0 text-blue-500" />
              </p>
            </Link>
          ))}
        </div>
      </div>

      <CTA />
    </Layout>
  );
}
